import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthCoreService } from './auth-core.service';
import { AuthCoreGuard } from './auth-core.guard';
import { AuthCoreInterceptor } from './auth-core.interceptor';

/**
 * Provides core authentication-related services.
 * handles login,logout and refresh token.
 * @author Imam Khaira
 * @since Aug 2021
 */
@NgModule({
  declarations: [],
  imports: [HttpClientModule],
  providers: [
    AuthCoreService,
    AuthCoreGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthCoreInterceptor,
      multi: true,
    },
  ],
  exports: [HttpClientModule],
})
export class AuthCoreModule {}
