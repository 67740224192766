import { Injectable } from '@angular/core';
import { CanLoad, Router, Route, UrlSegment, UrlTree, CanActivate } from '@angular/router';
import { isProduction } from 'src/env';
import { AuthCoreService } from './auth-core.service';

@Injectable({
  providedIn: 'root',
})
export class AuthCoreGuard implements CanLoad /*, CanActivate */ {
  constructor(private authService: AuthCoreService, private router: Router) {}

  /**
   * checks if a user is authenticated before accessing core functions.
   * otherwise will redirect to splash page.
   * @param _ - angular route. not used.
   * @param segments - segments of URL that we wanted to go.
   * @returns true if allowed, or redirect to splash if not.
   */
  canLoad(_: Route, segments: UrlSegment[]): boolean | UrlTree {
    if (!isProduction) return true;
    return this.authService.getAccessToken() !== null
      ? true
      : this.router.createUrlTree(['/auth/splash'], {
          queryParams: { target: segments.map((s) => s.path).join('/')},
        });
  }

}
