import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, OperatorFunction } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import {
  AuthResponse,
  MenuResource,
  ProfileMe,
  RefreshTokenAndRemember,
  RoleResourceType,
} from './auth-core.type';
import { clientId, clientSecret } from 'src/env';

@Injectable({
  providedIn: 'root',
})
export class AuthCoreService {
  // ==============================================================
  //                       Member Variables
  // ==============================================================

  public authenticatedUser: BehaviorSubject<ProfileMe | null> =
    new BehaviorSubject<ProfileMe | null>(null);
  public profileMe$: Observable<ProfileMe | null> = this.authenticatedUser
    .asObservable()
    .pipe(filter((val) => !!val));
  public isLogin = false;
  public roleAs = '' as unknown;
  public getBranchUuid: BehaviorSubject<string | undefined> =
    new BehaviorSubject<string | undefined>(undefined);
  public getIPAMBranchUuid: BehaviorSubject<string | undefined> =
    new BehaviorSubject<string | undefined>(undefined);

  public menuResource: BehaviorSubject<Array<RoleResourceType>> = new BehaviorSubject<Array<RoleResourceType>>([])
  public permissionResource$: Observable<Array<RoleResourceType>> = this.menuResource.asObservable()


  // ==============================================================
  //                 Constructor/Lifecycle Methods
  // ==============================================================

  //

  constructor(private http: HttpClient) {
    setTimeout(() => {
      if (this.getAccessToken() !== null) {
        this.getMe();
      }
    }, 200);
  }

  //

  // ==============================================================
  //                        Setters/Getters
  // ==============================================================

  //

  /** get access token stored in session */
  public getAccessToken(): string | null {
    return sessionStorage.getItem('access_token');
  }

  //

  // ==============================================================
  //                    Internal Control Methods
  // ==============================================================

  /**
   * returns an object that contains refresh token and remember
   * that will be true if the token is found on localstorage
   */
  private getRefreshTokenAndRemember(): RefreshTokenAndRemember {
    const refreshToken = localStorage.getItem('refresh_token');
    return refreshToken !== null
      ? {
          refreshToken,
          remember: true,
        }
      : {
          refreshToken: sessionStorage.getItem('refresh_token'),
          remember: false,
        };
  }

  //
  //

  /**
   * take action on response but does not modify the response observer.
   * @param remember whether to store refresh token in localstorage or not.
   * @returns tapping the response.
   */
  private handleAuthResponse(
    remember: boolean
  ): OperatorFunction<AuthResponse, AuthResponse> {
    return tap({
      next: (response: AuthResponse) => {
        sessionStorage.setItem('access_token', response.access_token);
        this.isLogin = true;
        localStorage.setItem('STATE', 'true');
        this.getMe()
        if (remember)
          localStorage.setItem('refresh_token', response.refresh_token);
        sessionStorage.setItem('refresh_token', response.refresh_token);
        this.isLogin = true;
        localStorage.setItem('STATE', 'true');
        // this.getMe()
      },
      error: (e: Error) => console.log('auth-core error: ', e.message),
    });
  }

  //

  // ==============================================================
  //                         Logic Methods
  // ==============================================================

  //

  /**
   * send authentication data to oauth server and process the data.
   * @param payload object that contains username, password and remember
   */
  public authenticate(payload: {
    username: string;
    password: string;
    remember: boolean;
  }): Observable<AuthResponse> {
    return this.http
      .post<AuthResponse>('/oauth', {
        username: payload.username,
        password: payload.password,
        grant_type: 'password',
        client_id: clientId,
        client_secret: clientSecret,
      })
      .pipe(this.handleAuthResponse(payload.remember));
  }

  //
  //

  /** get information user is logged into the system. */
  public getMe() {
    this.http.get<ProfileMe>(`/api/me`).subscribe({
      next: (resp: ProfileMe) => {
        this.authenticatedUser.next(resp);

        this.menuResource.next(resp.accessControlRole.roleResource)
        if (resp.branch !== null) this.getBranchUuid.next(resp.branch?.uuid);

        if (resp.ipamBranch !== null)
          this.getIPAMBranchUuid.next(resp.ipamBranch?.uuid);

        sessionStorage.setItem('role', resp?.accessControlRole?.roleName);

        localStorage.setItem('name', resp?.firstName + ' ' + (resp.lastName !==null ? resp.lastName : ''));
      },
    });
  }

  /**==========================================================================================
   *                              STATE PERMISSION RESOURCES                                   *
   ============================================================================================*/

  //  permissionResource


  /**==========================================================================================*
   *                              STATE PERMISSION RESOURCES                                   *
   ============================================================================================*/

  isLoggedIn() {
    const loggedIn = localStorage.getItem('STATE');
    if (loggedIn == 'true') this.isLogin = true;
    else this.isLogin = false;

    return this.isLogin;
  }

  public getRole() {
    this.roleAs = sessionStorage.getItem('role');
    return this.roleAs;
  }

  public getBranchPosition() {}

  /** attempt to get new access token from stored refreshToken.*/
  public reauthenticate() {
    const { refreshToken, remember } = this.getRefreshTokenAndRemember();
    return this.http
      .post<AuthResponse>('/oauth', {
        grant_type: 'refresh_token',
        client_id: clientId,
        client_secret: clientSecret,
        refresh_token: refreshToken,
      })
      .pipe(this.handleAuthResponse(remember));
  }

  //
  //

  /** revoke token and destroy it */
  public deauthenticate(): Observable<{ revoked: boolean }> {
    return this.http
      .post<{ revoked: true }>(
        '/api/v1/authrevoke',
        JSON.stringify({
          token_type_hint: 'access_token',
          token: this.getAccessToken(),
        })
      )
      .pipe(
        catchError(() => of({ revoked: true })),
        tap(() => {
          this.authenticatedUser.next(null)
          console.log('cleared');
          console.log('user state',this.authenticatedUser.value);
          localStorage.clear();
          sessionStorage.clear();
        })
      );
  }
}

// ==============================================================
//                       Member Variables
// ==============================================================

// ==============================================================
//                 Constructor/Lifecycle Methods
// ==============================================================

// ==============================================================
//                        Setters/Getters
// ==============================================================

// ==============================================================
//                    Internal Control Methods
// ==============================================================

// ==============================================================
//                         Logic Methods
// ==============================================================
