import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthCoreService } from './auth-core.service';
import { apiEndpoint } from 'src/env';

@Injectable()
export class AuthCoreInterceptor implements HttpInterceptor {
  private count = 1;
  private requestHeaders!: HttpHeaders;

  constructor(private authCoreService: AuthCoreService) {
    this.requestHeaders = new HttpHeaders({
      // 'Content-Type': 'application/json',
      Accept: 'application/json',
    });
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    const { url } = request;

    const createAuthorizedRequest = () =>
      request.clone({
        headers: request.headers
          .set("Accept", "*/*")
          .set(
            'Authorization',
            `Bearer ${this.authCoreService.getAccessToken()}`
          ),
      });

    if (url.includes('/api/')) {
      return next.handle(createAuthorizedRequest()).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401)
            return this.authCoreService
              .reauthenticate()
              .pipe(switchMap(() => next.handle(createAuthorizedRequest())));
          return throwError(error);
        })
      );
    }
    return next.handle(request);
  }
}
