export const appTitle = 'iBMS';
export const appSubtitle = 'PDAM Tirtanadi - Develop';
export const defaultDark = false;

export const isProduction = false;
export const baseUrl = 'https://dev.tirtanadi.ibmsindo.net';
export const apiEndpoint = 'https://dev.tirtanadi.ibmsindo.net';
export const transcoderEndpoint = 'https://dev.tirtanadi.ibmsindo.net:8300';
export const accessPointEndpoint = 'https://dev.tirtanadi.ibmsindo.net:8943';

// auth
export const clientId = 'bas-web-admin';
export const clientSecret = '1q2w3e4r';

// deployment
export const deployHost = 'dev.tirtanadi.ibmsindo.net';
export const deployUser = 'sysadmin';
export const deployPort = '2222';
export const deployDir = '/var/www/ibms-tirtanadi-dev/web-front-end';
export const deployConfig = 'production';

//refresh dashboard
export const refreshDashboard = 3600000
