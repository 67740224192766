import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthCoreModule } from './shared/services/auth-core/auth-core.module';
import { AuthCoreGuard } from './shared/services/auth-core/auth-core.guard';
import { SidebarLayoutLinksModule } from './shared/layouts/sidebar-layout/sidebar-layout-links.module';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/auth/splash',
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'jar-test',
    loadChildren: () =>
      import('./jar-test/jar-test.module').then((m) => m.JarTestModule),
  },
  {
    path: 'quality-data',
    loadChildren: () =>
      import('./quality-data/quality-data.module').then(
        (m) => m.QualityDataModule
      ),
  },
  {
    path: 'wtp-mini',
    loadChildren: () =>
      import('./wtp-mini/wtp-mini.module').then((m) => m.WtpMiniModule),
    canLoad: [AuthCoreGuard],
    // data: {
    //   role: 'admin'
    // }
  },
  {
    path: 'ipam',
    loadChildren: () => import('./ipam/ipam.module').then((m) => m.IpamModule),
    canLoad: [AuthCoreGuard],
    // data: {
    //   role: 'admin'
    // }
  },
  {
    path: 'sumur-bor',
    loadChildren: () =>
      import('./sumur-bor/sumur-bor.module').then((m) => m.SumurBorModule),
      canLoad: [AuthCoreGuard],

  },
  {
    path: 'preventive',
    loadChildren: () =>
      import('./preventive-data/preventive-data.module').then(
        (m) => m.PreventiveDataModule
      ),
    canLoad: [AuthCoreGuard],
  },

  {
    path: 'preventive-setting',
    loadChildren: () =>
      import('./preventive-data/preventive-data.module').then(
        (m) => m.PreventiveDataModule
      ),
    canLoad: [AuthCoreGuard],
  },
  {
    path: 'wash-out',
    loadChildren: () =>
      import('./wash-out/wash-out.module').then((m) => m.WashOutModule),
    canLoad: [AuthCoreGuard],
  },
  {
    path: 'setting',
    loadChildren: () =>
      import('./setting-pdam/setting-pdam.module').then(
        (m) => m.SettingPdamModule
      ),
    canLoad: [AuthCoreGuard],
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./profile/profile.module').then((m) => m.ProfileModule),
    canLoad: [AuthCoreGuard],
  },
  {
    path: '404',
    loadChildren: () =>
      import('./error404/error404.module').then((m) => m.Error404Module),
  },
  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [
    AuthCoreModule,
    // SidebarLayoutLinksModule.forRoot(navLinks),
    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
